<template>
  <div class="test-content">
      <b-tabs class="modal-add-question__tabs">
          <b-tab :title="$t('profile.test-bank.question-tab')" active class="modal-add-question__tab">
              <div class="modal-add-hw__content">
                  <div class="modal-add-hw__select">
                      <form-group class="tests-form-group" :label="$t('profile.test.name-ru')" >
                          <el-input v-model="question.title_ru" class="w-100" :placeholder="$t('profile.test.name-ru')" ></el-input>
                      </form-group>
                      <form-group class="tests-form-group" :label="$t('profile.test.name-kz')" >
                          <el-input v-model="question.title_kz" class="w-100" :placeholder="$t('profile.test.name-kz')" ></el-input>
                      </form-group>
                      <form-group class="tests-form-group" :label="$t('profile.test-bank.subject')">
                          <el-select
                              clearable
                              @change="getTopics"
                              v-model="question.subject_id"
                              class="w-100"
                              :placeholder="$t('profile.test-bank.subject')" >
                              <el-option v-for="(item, key) in subjects"
                                         :key="key"
                                         :label="item"
                                         :value="key">
                              </el-option>
                          </el-select>
                      </form-group>
                      <form-group class="tests-form-group" :label="$t('profile.test-bank.theme')">
                          <el-select clearable v-model="question.topic_id" class="w-100" :placeholder="$t('profile.test-bank.theme')">
                              <el-option v-for="item in topics"
                                         :key="item.id"
                                         :label="item.name"
                                         :value="item.id">
                              </el-option>
                          </el-select>
                      </form-group>
                      <form-group class="tests-form-group" :label="$t('profile.test.complexity')" >
                          <el-select clearable v-model="question.complexity" class="w-100" :placeholder="$t('profile.test.complexity')">
                              <el-option
                                  v-for="(item, key) in complexities"
                                  :key="key"
                                  :label="item"
                                  :value="key">
                              </el-option>
                          </el-select>
                      </form-group>
                      <form-group class="tests-form-group tests-form-group--header" :label="$t('profile.test.desc-ru')" >
                          <quill-editor
                              v-model="question.text_ru"
                              :options="editorOption"
                              @ready="onEditorReady($event)"
                          />
                      </form-group>
                      <form-group class="tests-form-group tests-form-group--header" :label="$t('profile.test.desc-kz')" >
                          <quill-editor
                              v-model="question.text_kz"
                              :options="editorOption"
                              @ready="onEditorReady($event)"
                          />
                      </form-group>
                      <form-group class="tests-form-group tests-form-group--header" :label="$t('profile.test.desc-kz')" >
                          <quill-editor
                              v-model="question.text_kz"
                              :options="editorOption"
                              @ready="onEditorReady($event)"
                          />
                      </form-group>
                      <form-group class="tests-form-group tests-form-group--header" :label="$t('profile.test.correct-comment-ru')" >
                          <el-input
                              type="textarea"
                              v-model="question.correct_comment_ru"
                          />
                      </form-group>
                      <form-group class="tests-form-group tests-form-group--header" :label="$t('profile.test.correct-comment-kz')" >
                          <el-input
                              type="textarea"
                              v-model="question.correct_comment_kz"
                          />
                      </form-group>
                      <form-group class="tests-form-group tests-form-group--header" :label="$t('profile.test.incorrect-comment-ru')" >
                          <el-input
                              type="textarea"
                              v-model="question.incorrect_comment_ru"
                          />
                      </form-group>
                      <form-group class="tests-form-group tests-form-group--header" :label="$t('profile.test.incorrect-comment-kz')" >
                          <el-input
                              type="textarea"
                              v-model="question.incorrect_comment_kz"
                          />
                      </form-group>
                  </div>
              </div>
              <div class="modal-add-hw__footer-btns">
                  <div class="d-flex flex-row">
                      <el-button type="primary" @click="saveQuestion">{{ $t('profile.test-bank.save') }}</el-button>
                      <el-button type="danger" @click="$router.push({ name: 'bank' })">
                          {{ $t('profile.test-bank.reset') }}
                      </el-button>
                      <el-button type="secondary" @click="preview(question.id)">{{ $t('profile.test-bank.view') }}</el-button>
                  </div>
              </div>
          </b-tab>
          <b-tab :title="$t('profile.test-bank.answer-tab')" class="modal-add-question__tab">
              <AddAnswers :question="question" :types="types" :create="false" />
          </b-tab>
      </b-tabs>
  </div>
</template>
<script>
import Vue from "vue";
import AddAnswers from "../../components/AddAnswers";
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'

export default {
    components: { AddAnswers, quillEditor },
    data() {
        return {
            editorOption: {
                modules: {
                    toolbar: [['bold', 'italic', 'image']],
                    resize: {}
                }
            },
            loading: false,
            question: {},
            filter: {
                id: '',
                name: '',
                type: '',
                subject: '',
                topic: '',
                complexity: '',
            },
            tab: 'info',
            currentPage: 1,
            total: 0,
            perPage: 1,
            subjects: [],
            topics: [],
            types: [],
            complexities: {
                1: 'Легкий',
                2: 'Средний',
                3: 'Сложный'
            },
        }
    },
    mounted() {
        let id = this.$route.params.id
        this.$http.get(`${window.API_ROOT}/api/question/${id}`)
            .then((res) => {
                this.loading = false;
                this.question = res.body.data
                this.subjects = res.body.subjects
                this.topics = res.body.topics
                this.types = res.body.types
            }).catch(() => {
                this.loading = false;
            })
    },
    methods: {
        saveQuestion() {
            let id = this.$route.params.id;
            this.$http.post(`${window.API_ROOT}/api/question/update/${id}`, this.question)
                .then((res) => {
                    this.loading = false;
                    if (res.body.status === 'success') {
                        Vue.toastr({
                            message: 'Успешно!',
                            description: 'Тест сохранен',
                            type: 'success'
                        });
                    }
                    this.$router.push({ name: 'bank' })
                }).catch(() => {
                    this.loading = false;
                })
        },
        getTopics(subjectId) {
            this.filter.topic = '';
            this.question.topic_id = '';
            this.topics = [];
            this.$http.get(`${window.API_ROOT}/api/reference/topics/${subjectId}`)
                .then((res) => {
                    this.topics = res.body.data
                })
                .catch(() => {
                })
        },
        preview(id) {
            this.$router.push({ name: 'question-preview', params: { id: id } })
        },
        onEditorReady(quill) {
            quill.enable(false)
            quill.on('selection-change', function () {
                quill.enable(true)
            })
        }
    }
}
</script>
<style lang="less" scoped>
.el-button {
    font-family: 'Roboto', sans-serif;
}
.el-button.el-button--default {
    display: block;
    color: #473F95;
    background-color: white;
    border: 1px solid #473F95;
}
.el-button.el-button--primary{
    display: block;
    color: white;
    background-color: #473F95;
    border: 1px solid #473F95;
}
.el-button.el-button--secondary {
    border-color: #EEEAFB;
    color: #473F95;
    background: #EEEAFB;
}
.el-button.el-button--danger {
    background: #D23168;
    border-color: #D23168;
}
.modal-add-question__tabs {
    padding: 0 30px;
}
/deep/ .nav-tabs {
    border: none;
}
/deep/ .nav-tabs .nav-link{
    text-align: center;
    padding: 10px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #473F95;
    border: none;
    border-bottom: 3px solid transparent;
}
/deep/ .nav-tabs .nav-link.active{
    border-bottom: 3px solid #473F95;
}
/deep/ .tab-content {
    margin-left: -30px;
    margin-right: -30px;
}
.tests-form-group-btns {
    background: #E3E2F2;
    border-radius: 4px 4px 0 0;
    padding: 9px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.tests-form-group-btns button {
    border: none;
    background-color: transparent;
    padding: 0;
}
/deep/ .tests-form-group .signin-label {
    color: #473F95;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
}
/deep/ .tests-form-group .el-input__inner {
    border: 2px solid #E3E2F2;
}
/deep/ .tests-form-group--header .tests-form-group__textarea {
    border-radius: 0 0 4px 4px;
    box-shadow: none;
    border: 2px solid #E3E2F2;
    font-size: 14px;
}
/deep/ .tests-form-group--header .tests-form-group__textarea::placeholder,
/deep/ .tests-form-group .el-input__inner::placeholder {
    color: #473F95;
    font-size: 13px;
    line-height: 15px;
}
.subject-width{
  width:200px
}
.theme-width{
  width:218px
}
.padding-test{
  padding-left: 30px;
  padding-right: 30px;
}
.test-wave{
  margin-top: 2rem;
  height: 90px;
  width:100%;
  background-image: url("/images/footer-wave-ktp.svg");
  background-size: cover;
  background-repeat: no-repeat;
}
.cursor-pointer {
    cursor: pointer;
}
.modal-add-hw__content {
    clear: both;
}
.el-icon-close {
    background: url("/images/profile/close-lila.svg") no-repeat center;
    text-indent: -9999px;
    display: block;
    width: 24px;
    height: 24px;
}
.tests-form-group{
  color: #473F95;
  margin-top: 20px;
  padding-left: 38px;
  padding-right: 38px;
}
.tests-table{
  margin-top: 27px;
  width:100%;
  thead{
    td{
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 35px;
      color: #473F95;

      border-radius: 4px;
      padding-right: 5px;

      div{
        border-radius: 4px;
        padding-left: 8px;
        background: #E3E2F2;
      }
    }
    td:last-child {
      padding-right: 0;
    }
  }

  tbody{
    td{
        padding-top: 20px;
        max-width: 370px;
        min-width: 66px;
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: #473F95;
        border-radius: 4px;
        padding-right: 5px;
        padding-left: 8px;
    }
    td:last-child {
        padding-right: 0;
    }
  }
}
    .tests-table thead .tests-table__header:nth-child(1),
    .tests-table tbody td:nth-child(1){
        min-width: 66px;
    }
    .tests-table thead .tests-table__header:nth-child(2),
    .tests-table tbody td:nth-child(2){
        min-width: 368px;
    }
    .tests-table thead .tests-table__header:nth-child(3),
    .tests-table tbody td:nth-child(3){
        min-width: 196px;
    }
    .tests-table thead .tests-table__header:nth-child(4),
    .tests-table tbody td:nth-child(4){
        min-width: 242px;
    }
.btn-red{
  cursor: pointer;
  user-select: none;
  border-radius: 5px;
  width:150px;
  color: white;
  border-color: #D23168;
  background-color: #D23168;
  text-align: center;
  display: flex;
  justify-content: center;
  span{
    padding-right: 10px;
    font-weight: bold;
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }
  div{
    line-height: 38px;
    font-size: 16px;
  }
}
.btn-purple{
  cursor: pointer;
  user-select: none;
  border-radius: 5px;
  width:150px;
  color: #473F95;;
  background-color: #EEEAFB;
  text-align: center;
  display: flex;
  justify-content: center;
  span{
    padding-right: 10px;
    font-weight: bold;
    font-size: 27px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }
}
.test-header-group{
  display: flex;
}
.test-header-btn{
  min-width:59px;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  user-select: none;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #473F95;
  border-bottom: 3px solid transparent;
}
.test-header-btn-active{
  border-bottom: 3px solid #473F95;
}
.test-header{
  display: flex;
  justify-content:space-between;
}
.test-content{
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04), 0 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  padding-top: 28px;
  max-width:880px;
  width: -webkit-fill-available;  
  background-color: white;
  margin: 0 auto;
}
.tests-table-block {
    overflow-x: auto;
    padding-left: 0;
}
.pagination {
    border: none;
    box-shadow: none;
    padding-top: 40px;
}
/deep/ .pagination .page-item .page-link {
    background: #EEEAFB;
    color: #473F95;
    border-radius: 5px;
    height: 45px;
    min-width: 58px;
    font-family: 'Roboto Condensed', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    border: none;
    margin: 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}
/deep/ .pagination .page-item[role=separator] .page-link {
    background: transparent;
    font-size: 28px;
    display: flex;
    align-items: flex-start;
}
/deep/ .pagination .page-item.active .page-link {
    background: #473F95;
    color: #fff;
}
/deep/ .pagination .page-item.active .page-link:focus {
    box-shadow: none;
}
/deep/ .pagination .page-item:last-child .page-link:hover,
/deep/ .pagination .page-item:first-child .page-link:hover {
    background: #EEEAFB;
    color: #473F95;
}
.test-bank-btns-bottom {
    margin-top: 38px;
    text-align: center;
    justify-content: center;
}
.test-bank-btn-save {
    color: #473F95;
    font-family: sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    background: #EEEAFB;
    border-radius: 5px;
    padding: 14px 36px;
    border: none;
    margin-right: 10px;
}
.test-bank-btn-cancel {
    color: #FFFFFF;
    font-family: sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    background: #D23168;
    border-radius: 5px;
    padding: 14px 36px;
    border: none;
}
.modal-add-hw__close {
    background-color: transparent;
    border-radius: 0;
    min-width: auto;
    width: fit-content;
    padding: 0;
    border: none;
    margin-left: auto;
}
.form-group {
    display: flex;
    flex-direction: column;
}
.modal-add-hw__select-btns {
    padding-top: 19px;
    padding-bottom: 67px;
}
.modal-add-hw__apply {
    background: #EEEAFB;
    border-radius: 5px;
    margin-right: 10px;
    color: #473F95;
    font-family: "Inter", "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    border: none;
    padding: 14px 36px;
}
.modal-add-hw__reset {
    color: #fff;
    font-family: "Inter", "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    background: #D23168;
    border-radius: 5px;
    padding: 14px 36px;
    border: none;
}
.add-hw-table {
    width: 100%;
    border-spacing: 5px;
    border-collapse: separate;
}
.add-hw-table-wrapper.loading {
    position: relative;
}
.add-hw-table-wrapper.loading::after {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: url('/images/preloader.gif');
    background-color: #ffffff9e;
    background-size: 25%;
    background-position: center;
    background-repeat: no-repeat;
}
.add-hw-table__header {
    color: #473F95;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    padding: 8px;
    border-radius: 4px;
    background: #E3E2F2;
}
.add-hw-table__check-box {
    width: 48px;
    text-align: center;
}
.add-hw-table__theme {
    width: 309px;
}
.add-hw-table__subject {
    width: 196px;
}
.add-hw-table__task-type {
    width: 242px;
}
.add-hw-table__theme,
.add-hw-table__subject,
.add-hw-table__task-type {
    color: #473F95;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    padding: 12px;
}
.add-hw-table__theme > div,
.add-hw-table__subject > div,
.add-hw-table__task-type > div {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
/deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
/deep/ .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #473F95;
    border-color: #473F95;
}
/deep/ .el-checkbox__inner:hover {
    border-color: #473F95;
}
/deep/ .el-checkbox__inner {
    width: 19px;
    height: 19px;
}
/deep/ .el-checkbox__inner::after {
    height: 9px;
    left: 6px;
    top: 2px;
    width: 4px;
}
.modal-add-hw__footer {
    text-align: center;
    margin-bottom: 18px;
}
.modal-add-hw__cancel {
    color: #473F95;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    border: 1px solid #473F95;
    box-sizing: border-box;
    border-radius: 5px;
    margin-right: 12px;
    background-color: transparent;
    padding: 13px 46px;
}
.modal-add-hw__save {
    color: #FFFFFF;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    border: 1px solid #473F95;
    background: #473F95;
    border-radius: 5px;
    padding: 13px 35px;
}
/deep/ #modal-add-question .modal-body {
    position: relative;
    background-image: url("/images/profile/wave-hw-modal.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
}
.add-questions-btn {
    float: right;
    background: #D23168;
    border-color: #D23168;
    font-family: 'Roboto', sans-serif;
}
.add-questions-btn i{
    font-weight: bold;
}
.modal-add-hw__footer-btns {
    text-align: center;
    margin: 30px 0;
    padding-left: 38px;
    padding-right: 38px;
}
@media (max-width: 991px) {
    .test-content {
        box-shadow: none;
        max-width: none;
        width: 100%;
        padding-top: 0;
    }
    .padding-test {
        padding: 0;
    }
    .tests-table-block {
        margin-right: 0;
        margin-left: 0;
    }
    .test-header {
        flex-direction: column;
        width: fit-content;
        margin: 0 auto;
    }
    .test-header .btn-red {
        width: 100%;
    }
    .test-header-group {
        margin-bottom: 32px;
    }
    .test-header-group .test-header-btn:first-child {
        margin-right: 8px;
    }
    .test-header-group .test-header-btn:last-child {
        width: 135px;
        justify-content: center;
    }
    .test-header-btn {
        color: #473F95;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.408px;
        padding: 9px 14px;
        background: #FDFEFF;
        border: 1px solid #473F95;
        box-sizing: border-box;
        backdrop-filter: blur(20px);
        border-radius: 12px;
    }
    .test-header-btn-active {
        color: #FFFFFF;
        background: #473F95;
        border: 1px solid #473F95;
    }
    .test-wave {
        display: none;
    }
    .btn-red--hide {
        display: none;
    }
    .tests-form-group {
        padding: 0;
    }
    /deep/ .page-item.active .page-link {
        z-index: 0;
    }
        .modal-add-hw__footer-btns {
        margin-top: 60px;
        margin-bottom: 80px;
    }
    .modal-add-hw__footer-btns .modal-add-hw__save,
    .modal-add-hw__footer-btns .modal-add-hw__cancel {
        padding: 13px 24px;
    }
    .modal-add-hw__select-btns {
        text-align: center;
    }
    .add-hw-table-wrapper {
        overflow-x: auto;
    }
    .add-hw-table > tr > .add-hw-table__header:nth-child(1) {
        min-width: 48px;
    }
    .add-hw-table > tr > .add-hw-table__header:nth-child(2) {
        min-width: 66px;
    }
    .add-hw-table > tr > .add-hw-table__header:nth-child(3) {
        min-width: 368px;
    }
    .add-hw-table > tr > .add-hw-table__header:nth-child(4) {
        min-width: 196px;
    }    
    .add-hw-table > tr > .add-hw-table__header:nth-child(5) {
        min-width: 242px;
    }
    .add-hw-table > tr > .add-hw-table__header:nth-child(6) {
        min-width: 242px;
    }
    #modal-add-hw .pagination {
        padding-top: 28px;
    }
    .pagination {
        margin-bottom: 0;
    }
    /deep/ .pagination .page-item .page-link {
        font-size: 14px;
        line-height: 16px;
        min-width: 38px;
    }
}
@media (max-width: 767px) {
    .pagination {
        margin-bottom: 0;
    }
    /deep/ .pagination .page-item .page-link {
        font-size: 14px;
        line-height: 16px;
        min-width: 38px;
    }
}

</style>